import React, { useEffect, useState } from "react";
import ReportHeader from "./Components/Header/ReportHeader";
import ReportSideBar from "./Components/Sidebar/ReportSideBar";
import { Box, Button, CssBaseline } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import {
  setData,
  retrieveAllIssue,
} from "../../../Redux/ClientSupportManagement/Action";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoading,
  selectMessage,
  selectIssueData,
} from "../../../Redux/ClientSupportManagement/Selector";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import "./index.css";
import IssueData from "./Components/IssueData";

const TicketReports = ({ isFromDashboard = false }) => {
  const [searchTicket, setSearchTicket] = useState("");
  const messageStatus = useSelector(selectMessage);
  const loadingStatus = useSelector(selectIsLoading);
  const IssueDataFiltered = useSelector(selectIssueData);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [select, setSelect] = React.useState(
    localStorage.getItem("SelectView")
      ? localStorage.getItem("SelectView")
      : "CardView"
  );

  const handleSubDrawerOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (viewType) => {
    localStorage.setItem("SelectView", viewType);
    setSelect(viewType);
  };

  useEffect(() => {
    const getUserRole = JSON.parse(localStorage.getItem("authRes")).role;

    let payloadData = IssueDataFiltered;
    if (searchTicket && select === "CardView") {
      payloadData = IssueDataFiltered.set("page", 1);
    }

    dispatch(
      retrieveAllIssue({
        roleId: getUserRole,
        filterData: {
          ...payloadData.toJS(),
          searchFilter: searchTicket,
        },
        viewType: select,
      })
    );
  }, [searchTicket, select]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <CssBaseline />
      <ReportHeader
        select={select}
        handleSelect={handleSelect}
        handleSubDrawerOpen={handleSubDrawerOpen}
        open={open}
        setSearchTicket={setSearchTicket}
      />
      <Box sx={{ display: open && "flex" }}>
        <IssueData
          select={select}
          isFromDashboard={isFromDashboard}
          searchTicket={searchTicket}
        />
        <ReportSideBar open={open} />
      </Box>
    </Box>
  );
};

export default TicketReports;
