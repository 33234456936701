import React, { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AgTable from "../../../../Utils/AgTable";

export default function AgTableForDashBoardTickets({ tickets, clientName }) {
  const [columnDefs, setColumnDefs] = useState([
    { field: "No", sortable: true, filter: true },
    { field: "Ticket No", sortable: true, filter: true },
    { field: "Name", sortable: true, filter: true },
    { field: "Email", sortable: true, filter: true },
    {
      field: "Description",
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        const fullText = params.value || "";
        const truncatedText = truncateText(fullText, 50);

        return <span title={fullText}>{truncatedText}</span>;
      },
      tooltipField: "description",
    },
    { field: "Status", sortable: true, filter: true },
    { field: "Priority", sortable: true, filter: true },
  ]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const rowData = tickets.map((item, index) => {
    return {
      No: index + 1,
      "Ticket No": item.get("ticketNo", ""),
      Description: item.get("description", ""),
      Status: item.get("status", ""),
      Priority: item.get("priority", ""),
      _id: item.get("_id", ""),
      Name: item.get("name", ""),
      Email: item.get("email", ""),
    };
  });

  return (
    <div
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        marginBottom: "15px",
      }}
    >
      <AgTable
        rows={rowData.toJS()}
        columns={columnDefs}
        setColumns={setColumnDefs}
      />
    </div>
  );
}
