import axios from "axios";
import { createAction } from "../../Utils/FunctionUtils";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");

export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const CREATE_ISSUE_REQUEST = "CREATE_ISSUE_REQUEST";
export const CREATE_ISSUE_SUCCESS = "CREATE_ISSUE_SUCCESS";
export const CREATE_ISSUE_FAILURE = "CREATE_ISSUE_FAILURE";
const createIssueRequest = createAction(CREATE_ISSUE_REQUEST);
const createIssueSuccess = createAction(CREATE_ISSUE_SUCCESS, "data");
const createIssueFailure = createAction(CREATE_ISSUE_FAILURE, "error");

export const createIssue = (payload) => {
  return (dispatch) => {
    dispatch(createIssueRequest());
    axios
      .post(`${backEndApi}support/createIssue`, payload)
      .then((res) => {
        dispatch(createIssueSuccess(res.data.message));
        dispatch(retrieveAllIssue());
      })
      .catch((error) => dispatch(createIssueFailure(error)));
  };
};

export const RETRIEVE_ALL_ISSUE_REQUEST = "RETRIEVE_ALL_ISSUE_REQUEST";
export const RETRIEVE_ALL_ISSUE_SUCCESS = "RETRIEVE_ALL_ISSUE_SUCCESS";
export const RETRIEVE_ALL_ISSUE_FAILURE = "RETRIEVE_ALL_ISSUE_FAILURE";
const retrieveAllIssueRequest = createAction(RETRIEVE_ALL_ISSUE_REQUEST);
const retrieveAllIssueSuccess = createAction(
  RETRIEVE_ALL_ISSUE_SUCCESS,
  "data"
);
const retrieveAllIssueFailure = createAction(
  RETRIEVE_ALL_ISSUE_FAILURE,
  "error"
);

export const retrieveAllIssue = (payload) => {
  return (dispatch) => {
    dispatch(retrieveAllIssueRequest());
    axios
      .post(`${backEndApi}support/retrieveIssue`, payload)
      .then((res) => {
        dispatch(retrieveAllIssueSuccess(res.data.message));
      })
      .catch((error) => dispatch(retrieveAllIssueFailure(error)));
  };
};

export const GET_INDIVIDUAL_ISSUE_REQUEST = "GET_INDIVIDUAL_ISSUE_REQUEST";
export const GET_INDIVIDUAL_ISSUE_SUCCESS = "GET_INDIVIDUAL_ISSUE_SUCCESS";
export const GET_INDIVIDUAL_ISSUE_FAILURE = "GET_INDIVIDUAL_ISSUE_FAILURE";

const getIndividualIssueRequest = createAction(GET_INDIVIDUAL_ISSUE_REQUEST);
const getIndividualIssueSuccess = createAction(
  GET_INDIVIDUAL_ISSUE_SUCCESS,
  "data"
);

const getIndividualIssueFailure = createAction(
  GET_INDIVIDUAL_ISSUE_FAILURE,
  "error"
);

export const getIndividualIssue = (id, callBack) => {
  return (dispatch) => {
    dispatch(getIndividualIssueRequest());
    axios
      .get(`${backEndApi}support/individualIssue?id=${id}`)
      .then((res) => {
        dispatch(getIndividualIssueSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getIndividualIssueFailure(error)));
  };
};

export const GET_SUPPORT_CLIENT_REQUEST = "GET_SUPPORT_CLIENT_REQUEST";
export const GET_SUPPORT_CLIENT_SUCCESS = "GET_SUPPORT_CLIENT_SUCCESS";
export const GET_SUPPORT_CLIENT_FAILURE = "GET_SUPPORT_CLIENT_FAILURE";
const getSupportClientRequest = createAction(GET_SUPPORT_CLIENT_REQUEST);
const getSupportClientSuccess = createAction(
  GET_SUPPORT_CLIENT_SUCCESS,
  "data"
);
const getSupportClientFailure = createAction(
  GET_SUPPORT_CLIENT_FAILURE,
  "error"
);

export const getSupportClient = () => {
  return (dispatch) => {
    dispatch(getSupportClientRequest());
    axios
      .get(`${backEndApi}support/retrieveClientName`)
      .then((res) => {
        dispatch(getSupportClientSuccess(res.data.message));
      })
      .catch((error) => dispatch(getSupportClientFailure(error)));
  };
};

export const CREATE_SUPPORT_CLIENT_REQUEST = "CREATE_SUPPORT_CLIENT_REQUEST";
export const CREATE_SUPPORT_CLIENT_SUCCESS = "CREATE_SUPPORT_CLIENT_SUCCESS";
export const CREATE_SUPPORT_CLIENT_FAILURE = "CREATE_SUPPORT_CLIENT_FAILURE";
const createSupportRequest = createAction(CREATE_SUPPORT_CLIENT_REQUEST);
const createSupportSuccess = createAction(
  CREATE_SUPPORT_CLIENT_SUCCESS,
  "data"
);
const createSupportFailure = createAction(
  CREATE_SUPPORT_CLIENT_FAILURE,
  "error"
);

export const createSupportClient = (payload, callBack) => {
  return (dispatch) => {
    dispatch(createSupportRequest());
    axios
      .post(`${backEndApi}support/createClientName`, payload)
      .then((res) => {
        dispatch(createSupportSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(createSupportFailure(error)));
  };
};

export const PUT_SUPPORT_CLIENT_REQUEST = "PUT_SUPPORT_CLIENT_REQUEST";
export const PUT_SUPPORT_CLIENT_SUCCESS = "PUT_SUPPORT_CLIENT_SUCCESS";
export const PUT_SUPPORT_CLIENT_FAILURE = "PUT_SUPPORT_CLIENT_FAILURE";
const putSupportRequest = createAction(PUT_SUPPORT_CLIENT_REQUEST);
const putSupportSuccess = createAction(PUT_SUPPORT_CLIENT_SUCCESS, "data");
const putSupportFailure = createAction(PUT_SUPPORT_CLIENT_FAILURE, "error");

export const putSupportClient = (payload, callBack) => {
  return (dispatch) => {
    dispatch(putSupportRequest());
    axios
      .put(`${backEndApi}support/updateClientName`, payload)
      .then((res) => {
        dispatch(putSupportSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(putSupportFailure(error)));
  };
};

export const DELETE_SUPPORT_CLIENT_REQUEST = "DELETE_SUPPORT_CLIENT_REQUEST";
export const DELETE_SUPPORT_CLIENT_SUCCESS = "DELETE_SUPPORT_CLIENT_SUCCESS";
export const DELETE_SUPPORT_CLIENT_FAILURE = "DELETE_SUPPORT_CLIENT_FAILURE";
const deleteSupportRequest = createAction(DELETE_SUPPORT_CLIENT_REQUEST);
const deleteSupportSuccess = createAction(
  DELETE_SUPPORT_CLIENT_SUCCESS,
  "data"
);
const deleteSupportFailure = createAction(
  DELETE_SUPPORT_CLIENT_FAILURE,
  "error"
);

export const deleteSupportClient = (id, callBack) => {
  return (dispatch) => {
    dispatch(deleteSupportRequest());
    axios
      .delete(`${backEndApi}support/deleteClientName?id=${id}`)
      .then((res) => {
        dispatch(deleteSupportSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(deleteSupportFailure(error)));
  };
};

export const UPDATE_CLIENT_ASSIGNED_PERSON_REQUEST =
  "UPDATE_CLIENT_ASSIGNED_PERSON_REQUEST";
export const UPDATE_CLIENT_ASSIGNED_PERSON_SUCCESS =
  "CLIENT_UPDATE_ASSIGNED_PERSON_SUCCESS";
export const UPDATE_CLIENT_ASSIGNED_PERSON_FAILURE =
  "UPDATE_CLIENT_ASSIGNED_PERSON_FAILURE";

const updateAssignedPersonRequest = createAction(
  UPDATE_CLIENT_ASSIGNED_PERSON_REQUEST
);
const updateAssignedPersonSuccess = createAction(
  UPDATE_CLIENT_ASSIGNED_PERSON_SUCCESS,
  "data"
);
const updateAssignedPersonFailure = createAction(
  UPDATE_CLIENT_ASSIGNED_PERSON_FAILURE,
  "error"
);

export const updateAssignedPerson = (payload, callBack) => {
  return (dispatch) => {
    dispatch(updateAssignedPersonRequest());
    axios
      .put(`${backEndApi}support/updateAssignedPerson`, payload)
      .then((res) => {
        dispatch(updateAssignedPersonSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(updateAssignedPersonFailure(error)));
  };
};

export const PUT_CLIENT_STATUS_REQUEST = "_PUT_CLIENT_STATUS_REQUEST";
export const PUT_CLIENT_STATUS_SUCCESS = "_PUT_CLIENT_STATUS_SUCCESS";
export const PUT_CLIENT_STATUS_FAILURE = "_PUT_CLIENT_STATUS_FAILURE";
const updateClientStatusRequest = createAction(PUT_CLIENT_STATUS_REQUEST);
const updateClientStatusSuccess = createAction(
  PUT_CLIENT_STATUS_SUCCESS,
  "data"
);
const updateClientStatusFailure = createAction(
  PUT_CLIENT_STATUS_FAILURE,
  "error"
);
export const updateClientStatus = (payload, callBack) => {
  return (dispatch) => {
    dispatch(updateClientStatusRequest());
    axios
      .put(`${backEndApi}support/changeStatus`, {
        ...payload,
      })
      .then((res) => {
        dispatch(updateClientStatusSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(updateClientStatusFailure(error)));
  };
};

export const PUT_CLIENT_PRIORITY_REQUEST = "_PUT_CLIENT_PRIORITY_REQUEST";
export const PUT_CLIENT_PRIORITY_SUCCESS = "_PUT_CLIENT_PRIORITY_SUCCESS";
export const PUT_CLIENT_PRIORITY_FAILURE = "_PUT_CLIENT_PRIORITY_FAILURE";
const putClientPriorityRequest = createAction(PUT_CLIENT_PRIORITY_REQUEST);
const putClientPrioritySuccess = createAction(
  PUT_CLIENT_PRIORITY_SUCCESS,
  "data"
);
const putClientPriorityFailure = createAction(
  PUT_CLIENT_PRIORITY_FAILURE,
  "error"
);
export const putClientPriority = (payload, callBack) => {
  return (dispatch) => {
    dispatch(putClientPriorityRequest());
    axios
      .put(`${backEndApi}support/${payload.supportId}/changePriority`, {
        priority: payload.newPriority,
      })
      .then((res) => {
        dispatch(putClientPrioritySuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(putClientPriorityFailure(error)));
  };
};

// createReplyMail

export const CREATE_CLIENT_REPLY_MAIL_REQUEST =
  "CREATE_CLIENT_REPLY_MAIL_REQUEST";
export const CREATE_CLIENT_REPLY_MAIL_SUCCESS =
  "CREATE_CLIENT_REPLY_MAIL_SUCCESS";
export const CREATE_CLIENT_REPLY_MAIL_FAILURE =
  "CREATE_CLIENT_REPLY_MAIL_FAILURE";
const createReplyMailRequest = createAction(CREATE_SUPPORT_CLIENT_REQUEST);
const createReplyMailSuccess = createAction(
  CREATE_CLIENT_REPLY_MAIL_SUCCESS,
  "data"
);
const createReplyMailFailure = createAction(
  CREATE_CLIENT_REPLY_MAIL_FAILURE,
  "error"
);

export const createReplyMail = (payload, callBack, viewTicketID) => {
  return (dispatch) => {
    dispatch(createReplyMailRequest());
    axios
      .post(`${backEndApi}support/createReplyMail`, payload)
      .then((res) => {
        dispatch(createReplyMailSuccess(res.data.message));
        dispatch(getIndividualIssue(viewTicketID, callBack));
      })
      .catch((error) => dispatch(createReplyMailFailure(error)));
  };
};

export const POST_FORWARD_EMAIL_REQUEST = "POST_FORWARD_EMAIL_REQUEST";
export const POST_FORWARD_EMAIL_SUCCESS = "POST_FORWARD_EMAIL_SUCCESS";
export const POST_FORWARD_EMAIL_FAILURE = "POST_FORWARD_EMAIL_FAILURE";

const postForwardEmailRequest = createAction(POST_FORWARD_EMAIL_REQUEST);
const postForwardEmailSuccess = createAction(POST_FORWARD_EMAIL_SUCCESS);
const postForwardEmailFailure = createAction(POST_FORWARD_EMAIL_FAILURE);

export const postForwardEmail = (payload, callback) => {
  return (dispatch) => {
    dispatch(postForwardEmailRequest());
    axios
      .post(`${backEndApi}support/sendForwardMail`, {
        mail: payload.mail,
        message: payload.message,
        subject: payload.subject,
        url: payload.url,
        Cc: payload.Cc,
        Bcc: payload.Bcc,
      })
      .then((res) => {
        dispatch(postForwardEmailSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(postForwardEmailFailure(error)));
  };
};

export const DELETE_TICKET_REQUEST = "DELETE_TICKET_REQUEST";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILURE = "DELETE_TICKET_FAILURE";

const deleteTicketRequest = createAction(DELETE_TICKET_REQUEST);
const deleteTicketSuccess = createAction(DELETE_TICKET_SUCCESS);
const deleteTicketFailure = createAction(DELETE_TICKET_FAILURE);

export const deleteTicket = (payload, callback) => {
  return (dispatch) => {
    dispatch(deleteTicketRequest());
    axios
      .post(`${backEndApi}support/deleteTicket`, { id: payload.id })
      .then((res) => {
        dispatch(deleteTicketSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(deleteTicketFailure(error)));
  };
};

export const FILTER_DATA_IN_CLIENT_SUPPORT_REQUEST = "DELETE_TICKET_REQUEST";
export const FILTER_DATA_IN_CLIENT_SUPPORT__SUCCESS = "DELETE_TICKET_SUCCESS";
export const FILTER_DATA_IN_CLIENT_SUPPORT__FAILURE = "DELETE_TICKET_FAILURE";

const filterDataInClientSupportRequest = createAction(DELETE_TICKET_REQUEST);
const filterDataInClientSupportSuccess = createAction(DELETE_TICKET_SUCCESS);
const filterDataInClientSupportFailure = createAction(DELETE_TICKET_FAILURE);

export const filterDataInClientSupport = (payload, callback) => {
  return (dispatch) => {
    dispatch(filterDataInClientSupportRequest());
    axios
      .post(`${backEndApi}support/filterDataInClientSupport`, {
        payload,
      })
      .then((res) => {
        dispatch(filterDataInClientSupportSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(filterDataInClientSupportFailure(error)));
  };
};

export const ALL_AGENT_LIST_DATA_REQUEST = "ALL_AGENT_DATA_REQUEST";
export const ALL_AGENT_LIST_DATA_SUCCESS = "ALL_AGENT_DATA_SUCCESS";
export const ALL_AGENT_LIST_DATA_FAILURE = "ALL_AGENT_DATA_FAILURE";

const allAgentListDataRequest = createAction(ALL_AGENT_LIST_DATA_REQUEST);
const allAgentListDataSuccess = createAction(
  ALL_AGENT_LIST_DATA_SUCCESS,
  "data"
);
const allAgentListDataFailure = createAction(
  ALL_AGENT_LIST_DATA_FAILURE,
  "error"
);

export const getAllAgentData = () => {
  return (dispatch) => {
    dispatch(allAgentListDataRequest());
    axios
      .get(`${backEndApi}FcmNotification/getAllAgent`)
      .then((res) => dispatch(allAgentListDataSuccess(res.data.message)))
      .catch((err) => dispatch(allAgentListDataFailure(err.message)));
  };
};

export const UPDATE_ASSIGNEE_NAME_REQUEST = "UPDATE_ASSIGNEE_NAME_REQUEST";
export const UPDATE_ASSIGNEE_NAME_SUCCESS = "UPDATE_ASSIGNEE_NAME_SUCCESS";
export const UPDATE_ASSIGNEE_NAME_FAILURE = "UPDATE_ASSIGNEE_NAME_FAILURE";

const updateAssigneeNameRequest = createAction(UPDATE_ASSIGNEE_NAME_REQUEST);
const updateAssigneeNameSuccess = createAction(
  UPDATE_ASSIGNEE_NAME_SUCCESS,
  "data"
);
const updateAssigneeNameFailure = createAction(
  UPDATE_ASSIGNEE_NAME_FAILURE,
  "error"
);

export const updateAssigneeName = (payload, callBack = () => {}) => {
  return (dispatch) => {
    dispatch(updateAssigneeNameRequest());
    axios
      .put(`${backEndApi}support/updateAssigneeName`, payload)
      .then((res) => {
        dispatch(updateAssigneeNameSuccess());
        callBack();
      })
      .catch((err) => {
        dispatch(updateAssigneeNameFailure(err.message));
      });
  };
};

// client support create new category
export const CREATE_SUPPORT_CATEGORY_REQUEST =
  "CREATE_SUPPORT_CATEGORY_REQUEST";
export const CREATE_SUPPORT_CATEGORY_SUCCESS =
  "CREATE_SUPPORT_CATEGORY_SUCCESS";
export const CREATE_SUPPORT_CATEGORY_FAILURE =
  "CREATE_SUPPORT_CATEGORY_FAILURE";
const createCategoryRequest = createAction(CREATE_SUPPORT_CATEGORY_REQUEST);
const createCategorySuccess = createAction(
  CREATE_SUPPORT_CATEGORY_SUCCESS,
  "message"
);
const createCategoryFailure = createAction(
  CREATE_SUPPORT_CATEGORY_FAILURE,
  "error"
);

export const createSupportCategory = (payload, callBack) => {
  return (dispatch) => {
    dispatch(createCategoryRequest());
    axios
      .post(`${backEndApi}support/category`, payload)
      .then((res) => {
        dispatch(createCategorySuccess(res.data.message));
      })
      .catch((error) => dispatch(createCategoryFailure(error)));
  };
};

// category list
export const ALL_CATEGORY_LIST_REQUEST = "ALL_CATEGORY_LIST_REQUEST";
export const ALL_CATEGORY_LIST_SUCCESS = "ALL_CATEGORY_LIST_SUCCESS";
export const ALL_CATEGORY_LIST_FAILURE = "ALL_CATEGORY_LIST_FAILURE";

const allCategoryListRequest = createAction(ALL_CATEGORY_LIST_REQUEST);
const allCategoryListSuccess = createAction(ALL_CATEGORY_LIST_SUCCESS, "data");
const allCategoryListFailure = createAction(ALL_CATEGORY_LIST_FAILURE, "error");

export const getAllCategoryList = () => {
  return (dispatch) => {
    dispatch(allCategoryListRequest());
    axios
      .get(`${backEndApi}support/categories`)
      .then((res) => dispatch(allCategoryListSuccess(res.data.message)))
      .catch((err) => dispatch(allCategoryListFailure(err.message)));
  };
};

// dashboard category counts and list
export const CATEGORY_COUNT_LIST_REQUEST = "CATEGORY_COUNT_LIST_REQUEST";
export const CATEGORY_COUNT_LIST_SUCCESS = "CATEGORY_COUNT_LIST_SUCCESS";
export const CATEGORY_COUNT_LIST_FAILURE = "CATEGORY_COUNT_LIST_FAILURE";

const categoryCountListRequest = createAction(CATEGORY_COUNT_LIST_REQUEST);
const categoryCountListSuccess = createAction(
  CATEGORY_COUNT_LIST_SUCCESS,
  "data"
);
const categoryCountListFailure = createAction(
  CATEGORY_COUNT_LIST_FAILURE,
  "error"
);

export const getAllCategoryAndCountList = () => {
  return (dispatch) => {
    dispatch(categoryCountListRequest());
    axios
      .get(`${backEndApi}support/categories-count`)
      .then((res) => dispatch(categoryCountListSuccess(res.data.message)))
      .catch((err) => dispatch(categoryCountListFailure(err.message)));
  };
};

// filtered dashboard tickets count base list
export const COUNT_BASED_TICKETS_LIST_REQUEST =
  "COUNT_BASED_TICKETS_LIST_REQUEST";
export const COUNT_BASED_TICKETS_LIST_SUCCESS =
  "COUNT_BASED_TICKETS_LIST_SUCCESS";
export const COUNT_BASED_TICKETS_LIST_FAILURE =
  "COUNT_BASED_TICKETS_LIST_FAILURE";

const categoryCountTicketListRequest = createAction(
  COUNT_BASED_TICKETS_LIST_REQUEST
);
const categoryCountTicketListSuccess = createAction(
  COUNT_BASED_TICKETS_LIST_SUCCESS,
  "data"
);
const categoryCountTicketListFailure = createAction(
  COUNT_BASED_TICKETS_LIST_FAILURE,
  "error"
);

export const getAllFilteredTicketList = (ticketIds) => {
  const queryString = ticketIds.map((id) => `ticketIds[]=${id}`).join("&");
  const url = `${backEndApi}support/categories-based-tickets?${queryString}`;
  return (dispatch) => {
    dispatch(categoryCountTicketListRequest());
    axios
      .get(url)
      .then((res) => dispatch(categoryCountTicketListSuccess(res.data.message)))
      .catch((err) => dispatch(categoryCountTicketListFailure(err.message)));
  };
};

// master categories list
export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FAILURE = "CATEGORIES_LIST_FAILURE";

const categoriesListRequest = createAction(CATEGORIES_LIST_REQUEST);
const categoriesListSuccess = createAction(CATEGORIES_LIST_SUCCESS, "data");
const categoriesListFailure = createAction(CATEGORIES_LIST_FAILURE, "error");

export const getCategoriesList = () => {
  return (dispatch) => {
    dispatch(categoriesListRequest());
    axios
      .get(`${backEndApi}support/categories-list`)
      .then((res) => dispatch(categoriesListSuccess(res.data.message)))
      .catch((err) => dispatch(categoriesListFailure(err.message)));
  };
};

// master categories update
export const CATEGORIES_UPDATE_REQUEST = "CATEGORIES_UPDATE_REQUEST";
export const CATEGORIES_UPDATE_SUCCESS = "CATEGORIES_UPDATE_SUCCESS";
export const CATEGORIES_UPDATE_FAILURE = "CATEGORIES_UPDATE_FAILURE";

const categoriesUpdateRequest = createAction(CATEGORIES_UPDATE_REQUEST);
const categoriesUpdateSuccess = createAction(
  CATEGORIES_UPDATE_SUCCESS,
  "message"
);
const categoriesUpdateFailure = createAction(
  CATEGORIES_UPDATE_FAILURE,
  "error"
);

export const updateExistCategories = ({ categoryId, categories, callBack }) => {
  return (dispatch) => {
    dispatch(categoriesUpdateRequest());
    axios
      .put(`${backEndApi}support/categories`, { categoryId, categories })
      .then((res) => {
        dispatch(categoriesUpdateSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || err.message || "An error occurred";
        dispatch(categoriesUpdateFailure(errorMessage));
      });
  };
};

// delete category
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

const deleteCategoryRequest = createAction(DELETE_CATEGORY_REQUEST);
const deleteCategorySuccess = createAction(DELETE_CATEGORY_SUCCESS, "message");
const deleteCategoryFailure = createAction(DELETE_CATEGORY_FAILURE, "error");

export const deleteExistingCategory = ({ categoryId, categoryType }) => {
  return (dispatch) => {
    dispatch(deleteCategoryRequest());
    axios
      .delete(
        `${backEndApi}support/categories?categoryId=${categoryId}&categoryType=${categoryType}`
      )
      .then((res) => {
        dispatch(deleteCategorySuccess(res.data.message));
        dispatch(getCategoriesList());
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || err.message || "An error occurred";
        dispatch(deleteCategoryFailure(errorMessage));
      });
  };
};
