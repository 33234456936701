import React, { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AgTable from "../../../../Utils/AgTable";
import { selectSupportClientList } from "../../../../Redux/ClientSupportManagement/Selector";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  updateClientStatus,
  putClientPriority,
} from "../../../../Redux/ClientSupportManagement/Action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router";
import { IconButton, MenuItem, Select } from "@mui/material";

const PopoverOptionsRenderer = ({
  options = [],
  handleChange,
  supportId,
  cellValue,
  cellWidth,
}) => {
  const getCellFontColor = () => {
    let textColor;
    if (cellValue === "Open" || cellValue === "Urgent") {
      textColor = "#bd2626";
    } else if (cellValue === "Close" || cellValue === "Low") {
      textColor = "#1e8544";
    } else if (cellValue === "Inprogress" || cellValue === "High") {
      textColor = "#807bdd";
    } else if (cellValue === "Waiting on Customer" || cellValue === "Medium") {
      textColor = "#b5550c";
    } else {
      textColor = "";
    }

    return textColor;
  };

  const getCellBgColor = () => {
    let bgColor;
    if (cellValue === "Open" || cellValue === "Urgent") {
      bgColor = "#fef2f2";
    } else if (cellValue === "Close" || cellValue === "Low") {
      bgColor = "#dcfce7";
    } else if (cellValue === "Inprogress" || cellValue === "High") {
      bgColor = "#eef2ff";
    } else if (cellValue === "Waiting on Customer" || cellValue === "Medium") {
      bgColor = "#fffbeb";
    } else {
      bgColor = "#f2f9ff";
    }

    return bgColor;
  };

  return (
    <div>
      <Select
        value={cellValue}
        sx={{
          height: "32px",
          width: cellWidth,
          padding: "5px",
          marginBottom: "5px",
          marginTop: "5px",
          color: getCellFontColor(),
          backgroundColor: getCellBgColor(),
          ".MuiSelect-icon": {
            color: getCellFontColor(),
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            value={item.value || item.username}
            onClick={() =>
              handleChange({ value: item.value || item.userId, supportId })
            }
          >
            {item.value || item.username}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default function TicketsListAgTableView({
  tickets,
  callBack,
  allAgents,
  handleChangeAssigneeName,
}) {
  const priorityOptions = [
    { value: "Low", icon: <ArrowRightAltIcon /> },
    { value: "Medium", icon: <ArrowRightAltIcon /> },
    { value: "High", icon: <ArrowRightAltIcon /> },
    { value: "Urgent", icon: <ArrowRightAltIcon /> },
  ];
  const statusOptions = [
    { value: "Open", icon: <ArrowRightAltIcon /> },
    { value: "Close", icon: <ArrowRightAltIcon /> },
    { value: "Inprogress", icon: <ArrowRightAltIcon /> },
    { value: "Waiting on Customer", icon: <ArrowRightAltIcon /> },
  ];

  const agentList = [...allAgents, { username: "No Assignee", userId: "" }];
  const [columnDefs, setColumnDefs] = useState([
    { field: "No", sortable: true, filter: true, width: 70 },
    { field: "Ticket No", sortable: true, filter: true, width: 120 },
    { field: "Client Name", sortable: true, filter: true, width: 130 },
    { field: "ClientType", sortable: true, filter: true, width: 120 },
    { field: "Issue Type", sortable: true, filter: true, width: 120 },
    { field: "Date", sortable: true, filter: true, width: 130 },
    { field: "Name", sortable: true, filter: true, width: 150 },
    { field: "Email", sortable: true, filter: true },
    {
      field: "Status",
      headerName: "Status",
      cellRenderer: (params) => {
        const status = params.value;
        const supportId = params.data._id;

        return (
          <div className="d-flex justify-content-between bg-transparent">
            <PopoverOptionsRenderer
              options={statusOptions}
              handleChange={handleStatusChange}
              supportId={supportId}
              cellValue={status}
              cellWidth={"134px"}
            />
          </div>
        );
      },
      width: 180,
    },
    {
      field: "Assignee",
      cellRenderer: (params) => {
        const assignee =
          params.value || getAssigneeName({ assigneeId: params.data.assignee });
        const supportId = params.data._id;

        return (
          <div className="d-flex justify-content-between bg-transparent">
            <PopoverOptionsRenderer
              options={agentList}
              handleChange={handleChangeAssigneeForTicket}
              supportId={supportId}
              cellValue={assignee}
              cellWidth={"170px"}
            />
          </div>
        );
      },
      width: 200,
    },
    {
      field: "Priority",
      cellRenderer: (params) => {
        const priority = params.value;
        const supportId = params.data._id;

        return (
          <div className="d-flex justify-content-between bg-transparent">
            <PopoverOptionsRenderer
              options={priorityOptions}
              handleChange={handlePriorityChange}
              supportId={supportId}
              cellValue={priority}
              cellWidth={"120px"}
            />
          </div>
        );
      },
      width: 170,
    },
    {
      field: "Action",
      cellRenderer: (params) => {
        return (
          <IconButton onClick={(e) => handleView(params.data._id)}>
            <VisibilityIcon sx={{ color: "#1976D2" }} />
          </IconButton>
        );
      },
      width: 80,
    },
  ]);
  const supportClientList = useSelector(selectSupportClientList).toJS();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getClientName = (clientId) => {
    const clientName = supportClientList.find(
      ({ _id }) => clientId === _id
    )?.clientName;

    return clientName;
  };

  const rowData = tickets.map((item, index) => {
    return {
      No: index + 1,
      "Ticket No": item.get("ticketNo", ""),
      Name: item.get("name", ""),
      "Issue Type": item.get("type", ""),
      "Client Name": getClientName(item.get("clientId", "")),
      Status: item.get("status", ""),
      Priority: item.get("priority", ""),
      _id: item.get("_id", ""),
      Email: item.get("email", ""),
      ClientType: item.get("clientType", ""),
      Date: new Date(item.get("createdAt", "")).toLocaleDateString(),
      assignee: item.get("assignee", ""),
    };
  });

  const handleStatusChange = ({ value, supportId }) => {
    const payload = {
      supportId,
      currentStatus: value,
    };

    dispatch(updateClientStatus(payload, callBack));
  };

  const handlePriorityChange = ({ value, supportId }) => {
    const payload = {
      supportId,
      newPriority: value,
    };

    dispatch(putClientPriority(payload, callBack));
  };

  const handleChangeAssigneeForTicket = ({ value, supportId }) => {
    handleChangeAssigneeName({ id: supportId, AssigneeId: value });
  };

  const getAssigneeName = ({ assigneeId = "" }) => {
    if (!assigneeId) return "No Assignee";

    const assigneeName = allAgents.find(
      (item) => item.userId === assigneeId
    )?.username;

    return assigneeName;
  };

  const handleView = (id, key = "") => {
    if (key === "ReplyPage") {
      navigate(`/clientSupport/viewTicket?id=${id}`);
    } else {
      navigate(`/clientSupport/viewIssue?id=${id}`);
    }
  };

  return (
    <AgTable
      rows={rowData.toJS()}
      columns={columnDefs}
      setColumns={setColumnDefs}
      pageDataCount={10}
      handleView={handleView}
    />
  );
}
