import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Button, Divider, MenuItem, TextField } from "@mui/material";
import { List, Map } from "immutable";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateExistCategories,
  getCategoriesList,
} from "../../../../Redux/ClientSupportManagement/Action";
import DeleteCategoryConfirmPopUp from "../models/DeleteCategoryConfirmPopUp";

export default function CategoryView({
  open,
  setOpen,
  categoryId,
  categoryName,
  subCategories,
}) {
  const [newSubCategories, setNewSubCategory] = useState(List());
  const [deleteCategoryId, setDeleteOpen] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (subCategories.size !== 0 && newSubCategories.size === 0) {
      const firstIndexSubCategory = subCategories.get(0);
      const updatedCategories = newSubCategories.push(
        Map({
          category: firstIndexSubCategory.get("category", ""),
          section: firstIndexSubCategory.get("sections", List()),
          _id: firstIndexSubCategory.get("_id", ""),
        })
      );

      setNewSubCategory(updatedCategories);
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const getSubCategory = (option) => {
    const isExists = newSubCategories.find(
      (item) => item.get("_id", "") === option.get("_id", "")
    );

    if (!isExists) {
      const updatedCategories = newSubCategories.push(
        Map({
          category: option.get("category", ""),
          section: option.get("sections", List()),
          _id: option.get("_id", ""),
        })
      );

      setNewSubCategory(updatedCategories);
    }
  };

  const createNewSubCategory = () => {
    const updatedCategories = newSubCategories.push(
      Map({
        category: "",
        section: List(),
      })
    );

    setNewSubCategory(updatedCategories);
  };

  const deleteCreatedSubCategory = (index, id) => {
    const updatedCategories = newSubCategories.filter(
      (_, categoryIndex) => categoryIndex !== index
    );

    setNewSubCategory(updatedCategories);

    if (id) {
      setDeleteOpen(id);
      setCategoryType("SUB_CATEGORY");
    }
  };

  const updateSubCategoryName = (index, name) => {
    const data = newSubCategories.setIn([index, "category"], name);
    setNewSubCategory(data);
  };

  const createSection = (item, index) => {
    const sections = item.get("section", List());
    const updatedSections = sections.push(Map({ category: "" }));
    const updatedSubCategory = item.set("section", updatedSections);
    const updatedList = newSubCategories.set(index, updatedSubCategory);
    setNewSubCategory(updatedList);
  };

  const deleteSection = (subCategoryIndex, sectionIndex, id) => {
    const subCategory = newSubCategories.get(subCategoryIndex);
    const sections = subCategory
      .get("section", List())
      .filter((_, index) => index !== sectionIndex);
    const updatedSubCategory = subCategory.set("section", sections);

    const updatedList = newSubCategories.set(
      subCategoryIndex,
      updatedSubCategory
    );
    setNewSubCategory(updatedList);

    if (id) {
      setDeleteOpen(id);
      setCategoryType("SECTION");
    }
  };

  const updateSectionName = (subCategoryIndex, sectionIndex, name) => {
    const data = newSubCategories.setIn(
      [subCategoryIndex, "section", sectionIndex, "category"],
      name
    );
    setNewSubCategory(data);
  };

  const callBack = () => {
    dispatch(getCategoriesList());
  };

  const updateCategoryWithApiCall = () => {
    dispatch(
      updateExistCategories({
        categoryId,
        categories: newSubCategories.toJS(),
        callBack,
      })
    );

    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <div className="d-flex justify-content-between">
          Update Category Details
          <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-3">
          <div>
            Category Name : <span className="mx-2">{categoryName}</span>
          </div>
          <div>
            Sub Categories :
            {subCategories.size === 0 ? (
              <span className="mx-2">No SubCategories</span>
            ) : (
              <span className="mx-2">
                <TextField
                  id="outlined-select-currency"
                  select
                  defaultValue={
                    subCategories.size > 0
                      ? subCategories.getIn([0, "category"])
                      : ""
                  }
                  size="small"
                >
                  {subCategories.map((option) => (
                    <MenuItem
                      key={option.get("category", "")}
                      value={option.get("category", "")}
                      onClick={() => getSubCategory(option)}
                    >
                      {option.get("category", "")}
                    </MenuItem>
                  ))}
                </TextField>
              </span>
            )}
          </div>
          <div>
            <span
              className="text-primary cursor-pointer"
              onClick={createNewSubCategory}
            >
              + Create subCategory
            </span>

            {newSubCategories.size !== 0 &&
              newSubCategories.map((item, index) => (
                <div className="d-flex flex-column mt-3 gap-2">
                  <div>
                    <TextField
                      size="small"
                      placeholder="Enter Sub Category"
                      onChange={(e) =>
                        updateSubCategoryName(index, e.target.value)
                      }
                      value={item.get("category", "")}
                    />
                    <IconButton>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          deleteCreatedSubCategory(index, item.get("_id", ""))
                        }
                      />
                    </IconButton>
                    <span
                      className="text-primary cursor-pointer mt-2"
                      onClick={() => createSection(item, index)}
                    >
                      + Create Section
                    </span>
                  </div>
                  <div>
                    {item.get("section", List()).size !== 0 &&
                      item
                        .get("section", List())
                        .map((sectionElement, sectionIndex) => (
                          <div className="d-flex mt-3">
                            <TextField
                              size="small"
                              placeholder="Enter Section"
                              value={sectionElement.get("category", "")}
                              onChange={(e) =>
                                updateSectionName(
                                  index,
                                  sectionIndex,
                                  e.target.value
                                )
                              }
                            />
                            <IconButton>
                              <DeleteIcon
                                sx={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  deleteSection(
                                    index,
                                    sectionIndex,
                                    sectionElement.get("_id", "")
                                  )
                                }
                              />
                            </IconButton>
                          </div>
                        ))}
                  </div>
                  <Divider />
                </div>
              ))}
          </div>
          {deleteCategoryId && (
            <DeleteCategoryConfirmPopUp
              categoryId={deleteCategoryId}
              setOpen={setDeleteOpen}
              isFromUpdatePopUp={true}
              closeUpdatePopUp={setOpen}
              categoryType={categoryType}
            />
          )}
          <div className="d-flex justify-content-end">
            <Button variant="contained" onClick={updateCategoryWithApiCall}>
              Update
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
