import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getCategoriesList,
  setData,
} from "../../../Redux/ClientSupportManagement/Action";
import {
  selectMasterCategoryList,
  selectMessage,
} from "../../../Redux/ClientSupportManagement/Selector";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoryView from "./models/CategoryView";
import { fromJS, List, Map } from "immutable";
import { IconButton } from "@mui/material";
import DeleteCategoryConfirmPopUp from "./models/DeleteCategoryConfirmPopUp";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import AgTable from "../../../Utils/AgTable";

export default function ClientMaster() {
  const [openCategoryView, setOpenView] = useState(false);
  const [categoryData, setCategory] = useState(Map());
  const [deleteCategoryId, setDeleteOpen] = useState("");
  const [columnDefs, setColumnDefs] = useState([
    { field: "No", sortable: true, filter: true },
    { field: "Category Name", sortable: true, filter: true },
    {
      field: "SubCategoriesCount",
      headerName: "Sub Categories",
      sortable: true,
      filter: true,
    },
    {
      field: "SectionsCount",
      headerName: "Sections",
      sortable: true,
      filter: true,
    },
    {
      field: "Action",
      sortable: false,
      filter: false,
      cellRenderer: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            marginRight: "0px",
          }}
        >
          <IconButton disabled={isThisUnCategory(params.data)}>
            <ModeEditIcon
              sx={{
                color: isThisUnCategory(params.data) ? "gray" : "blue",
                cursor: "pointer",
              }}
              onClick={() => setOpenCategoryView(params.data)}
            />
          </IconButton>
          <IconButton disabled={isThisUnCategory(params.data)}>
            <DeleteIcon
              sx={{
                color: isThisUnCategory(params.data) ? "gray" : "red",
                cursor: "pointer",
              }}
              onClick={() => deleteCategoryFromDb(params.data._id)}
            />
          </IconButton>
        </div>
      ),
    },
  ]);
  const dispatch = useDispatch();
  const categoryList = useSelector(selectMasterCategoryList);
  const messageStatus = useSelector(selectMessage);

  useEffect(() => {
    dispatch(getCategoriesList());
  }, []);

  const isThisUnCategory = (data) => {
    const name = data["Category Name"];

    return name === "unCategory" ? true : false;
  };

  const rowData = categoryList.map((item, index) => {
    return {
      No: index + 1,
      "Category Name": item.get("category", ""),
      SubCategoriesCount: item.get("subCategoryCount", ""),
      SectionsCount: item.get("sectionCount", ""),
      _id: item.get("_id", ""),
      subCategories: item.get("subCategories", List()),
    };
  });

  const setOpenCategoryView = (data) => {
    setCategory(fromJS(data));
    setOpenView(true);
  };

  const deleteCategoryFromDb = (id) => {
    setDeleteOpen(id);
  };

  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <AgTable
        rows={rowData.toJS()}
        columns={columnDefs}
        setColumns={setColumnDefs}
        agTableWidth={"1054px"}
        agContainerStyle={{
          display: "flex",
          justifyContent: "center",
          margin: "20px",
        }}
      />

      {openCategoryView && (
        <CategoryView
          open={openCategoryView}
          setOpen={setOpenView}
          categoryId={categoryData.get("_id", "")}
          categoryName={categoryData.get("category", "")}
          subCategories={categoryData.get("subCategories", List())}
        />
      )}
      {deleteCategoryId && (
        <DeleteCategoryConfirmPopUp
          categoryId={deleteCategoryId}
          setOpen={setDeleteOpen}
        />
      )}
    </>
  );
}
