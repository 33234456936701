import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CustomCKEditor({ data, handleChange }) {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        config={{
          licenseKey:
            "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3MzUwODQ3OTksImp0aSI6IjFkNWY1MDk1LWRlZTQtNDVmNi05NjhmLWY2NTBjZmQyOWQ1YSIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiLCJzaCJdLCJ3aGl0ZUxhYmVsIjp0cnVlLCJsaWNlbnNlVHlwZSI6InRyaWFsIiwiZmVhdHVyZXMiOlsiKiJdLCJ2YyI6IjNhMTQzNGZhIn0.7BhAXHI4_uXARFzH5i-yo4fVFBEpFNpq_KylzS7DR4nZTxEo1iW9enS_KvELqQXtyosvkJXTAFLNb_wopBC72Q",
        }}
        data={data}
        onChange={(event, editor) => handleChange(editor)}
      />
      ;
    </div>
  );
}
