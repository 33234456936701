import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { List, Map } from "immutable";
import { useDispatch } from "react-redux";
import { createSupportCategory } from "../../../../Redux/ClientSupportManagement/Action";
import CancelIcon from "@mui/icons-material/Cancel";

const TextBox = ({
  index,
  handleChange,
  subIndex,
  value,
  handleNewSection,
  lastIndex,
  handleRemove,
}) => {
  const isLastElement = subIndex === lastIndex;

  return (
    <div className="gap-3">
      <div className="d-flex align-items-center gap-3">
        <div>Section {subIndex + 1} Name :</div>
        <TextField
          placeholder="Enter Section Name"
          sx={{ width: "65%" }}
          value={value}
          onChange={(e) =>
            handleChange({
              key: "section",
              index,
              subIndex,
              value: e.target.value,
            })
          }
        />
        {handleRemove && (
          <CancelIcon
            sx={{ color: "red" }}
            onClick={() => handleRemove({ subIndex })}
          />
        )}
      </div>
      <div className="mt-3">
        {isLastElement && (
          <Button
            startIcon={<AddIcon />}
            sx={{ textTransform: "none" }}
            onClick={handleNewSection}
            disabled={!value}
          >
            Add Section
          </Button>
        )}
      </div>
    </div>
  );
};

const Section = ({
  open,
  onClose,
  handleChange,
  index,
  category,
  setCategory,
}) => {
  const handleClose = () => {
    onClose(false);
  };

  const handleNewSection = () => {
    const categorySection = category.getIn(
      ["subCategories", index, "section"],
      List()
    );
    const updateSection = categorySection.push(Map({ category: "" }));
    const updateSubCategory = category.setIn(
      ["subCategories", index, "section"],
      updateSection
    );
    setCategory(updateSubCategory);
  };

  const handleRemove = ({ subIndex }) => {
    const updatedCategory = category.updateIn(
      ["subCategories", index, "section"],
      (sectionList) => sectionList.delete(subIndex)
    );

    setCategory(updatedCategory);
  };

  const lastIndex =
    category.getIn(["subCategories", index, "section"], List()).size - 1;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle className="mb-2 fs-4">Create Section</DialogTitle>
      <DialogContent>
        {category.getIn(["subCategories", index, "section"], List()).size !==
        0 ? (
          category
            .getIn(["subCategories", index, "section"], List())
            .map((item, subIndex) => (
              <TextBox
                handleChange={handleChange}
                index={index}
                subIndex={subIndex}
                value={item.get("category")}
                category={category}
                handleNewSection={handleNewSection}
                lastIndex={lastIndex}
                handleRemove={handleRemove}
              />
            ))
        ) : (
          <TextBox
            handleChange={handleChange}
            index={index}
            subIndex={0}
            category={category}
            lastIndex={true}
          />
        )}
      </DialogContent>
      <DialogActions className="mx-5 gap-3">
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={handleClose}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NestedCategory = ({
  text,
  index,
  handleRemove,
  handleUpdate,
  section,
  category,
  setCategory,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-2 mb-3">
        <div className="fs-5">{`${text} :`} </div>
        <div className="d-flex align-items-center gap-2">
          <TextField
            placeholder={`Enter ${text}`}
            onChange={(e) =>
              handleUpdate({
                key: "subCategories",
                index,
                value: e.target.value,
              })
            }
          />
          <CancelIcon
            onClick={() => handleRemove(index)}
            sx={{ color: "red" }}
          />
          <Button
            startIcon={<AddIcon />}
            sx={{ textTransform: "none" }}
            onClick={handleOpen}
            disabled={!section}
          >
            Add Section
          </Button>
          {open && (
            <Section
              open={open}
              onClose={setOpen}
              handleChange={handleUpdate}
              index={index}
              category={category}
              setCategory={setCategory}
            />
          )}
        </div>
      </div>

      {category.getIn(["subCategories", index, "section"], List()).size !==
        0 && (
        <div className="d-flex flex-column mb-2">
          <div className="fs-5 text-primary">Created Sections :</div>
          <ol>
            {category
              .getIn(["subCategories", index, "section"], List())
              .filter((item) => item.get("category")) // Filter out empty values
              .map((item, subIndex) => (
                <li key={subIndex}>
                  <span>{item.get("category")}</span>
                </li>
              ))}
          </ol>
        </div>
      )}
    </>
  );
};

export default function CreateCategory({ isPopup, setPopup }) {
  const [category, setCategory] = useState(Map({ subCategories: List() }));
  const dispatch = useDispatch();

  const handleClose = () => {
    setPopup(false);
  };

  const handleChange = (value) => {
    const updatedCategory = category.set("category", value);
    setCategory(updatedCategory);
  };

  const handleSubCategory = () => {
    const subCategories = category.get("subCategories", List());
    const newCategory = subCategories.push(
      Map({ category: "", section: List() })
    );
    const updatedSubCategories = category.set("subCategories", newCategory);
    setCategory(updatedSubCategories);
  };

  const handleRemove = (index) => {
    const updatedCategory = category.get("subCategories", List()).delete(index);
    const updateSubCategory = category.set("subCategories", updatedCategory);
    setCategory(updateSubCategory);
  };

  const handleUpdate = ({ key, index, subIndex, value }) => {
    let updatedData = category;

    if (key === "subCategories") {
      updatedData = category.setIn(["subCategories", index, "category"], value);
    } else {
      if (
        !category.hasIn([
          "subCategories",
          index,
          "section",
          subIndex,
          "category",
        ])
      ) {
        updatedData = category.setIn(
          ["subCategories", index, "section", subIndex],
          Map({ category: "" })
        );
      }
      updatedData = updatedData.setIn(
        ["subCategories", index, "section", subIndex, "category"],
        value
      );
    }

    setCategory(updatedData);
  };

  const handleCreate = () => {
    dispatch(createSupportCategory(category));
    setPopup(false);
  };

  const isFilled = category.get("category", "");

  return (
    <Dialog open={isPopup} onClose={handleClose} fullWidth>
      <DialogTitle className="mb-2 fs-4">Create Category</DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-2 mb-2">
            <div className="fs-5 mx-1">Category :</div>
            <TextField
              placeholder="Enter Category Name"
              sx={{ width: "65%" }}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          {category.get("subCategories", List()).size > 0 && (
            <div>
              {category.get("subCategories", List()).map((item, index) => (
                <div className="mb-2">
                  <NestedCategory
                    text={
                      category.get("subCategories", List()).size > 1
                        ? `SubCategory ${index + 1}`
                        : "SubCategory"
                    }
                    index={index}
                    handleRemove={handleRemove}
                    handleUpdate={handleUpdate}
                    section={
                      category.getIn(
                        ["subCategories", index, "category"],
                        ""
                      ) !== ""
                    }
                    category={category}
                    setCategory={setCategory}
                  />
                  <Divider />
                </div>
              ))}
              <Button
                startIcon={<AddIcon />}
                sx={{ textTransform: "none" }}
                onClick={handleSubCategory}
                disabled={!isFilled}
              >
                Add SubCategory
              </Button>
            </div>
          )}
          {category.get("subCategories", List()).size === 0 && (
            <Button
              startIcon={<AddIcon />}
              sx={{ textTransform: "none" }}
              onClick={handleSubCategory}
              disabled={!isFilled}
            >
              Add SubCategory
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions className="mb-2 mx-5 gap-3">
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleCreate} variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
