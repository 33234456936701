import React from "react";
import {
  selectIssueData,
  selectSupportList,
  selectAllAgentData,
} from "../../../../Redux/ClientSupportManagement/Selector";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  setData,
  retrieveAllIssue,
  getSupportClient,
  getAllAgentData,
  updateAssigneeName,
} from "../../../../Redux/ClientSupportManagement/Action";
import ReportsCardList from "./ReportsCardList/ReportsCardList";
import ReportsTable from "./ReportsTable/ReportsTable";
import { Box } from "@mui/material";
import { List, fromJS } from "immutable";
import PaginationControlled from "../../../../Shared/Pagination";
import { duplicateNumberForNoAssignee } from "../../../../Utils/DuplicateNumber";
import TicketsListAgTableView from "../Models/TicketsListAgTableView";

/*-------------------------------------UtilStart-------------------------------------*/
const BoxStyle = {
  width: "100%",
  background: "#EBEFF3",
};
const componentStyle = { height: "calc(100vh - 130px )", overflow: "auto" };
const rowsPerPage = 10;
const DuplicateAgentData = [
  { username: "No Assignee", userId: duplicateNumberForNoAssignee },
];

/*-------------------------------------UtilEnd---------------------------------------*/

/*-------------------------------------CustomHooksStart------------------------------*/
const useReduxData = () => {
  const IssueDataFiltered = useSelector(selectIssueData);
  const AllIssueData = useSelector(selectSupportList);
  const AllAgent = useSelector(selectAllAgentData);
  return [AllIssueData, IssueDataFiltered, AllAgent];
};

const useCommonDispatch = () => {
  const dispatch = useDispatch();
  const callAllIssue = (data) => dispatch(retrieveAllIssue(data));
  const SupportClient = () => dispatch(getSupportClient());
  const setReduxData = (data) => dispatch(setData(data));
  const allAgentData = () => dispatch(getAllAgentData());
  const updateAssignee = (data, callBack) =>
    dispatch(updateAssigneeName(data, callBack));
  return [
    callAllIssue,
    SupportClient,
    setReduxData,
    allAgentData,
    updateAssignee,
  ];
};

const useCallIssueDataApi = ({
  IssueDataFiltered,
  callAllIssue,
  allAgentData,
  AllAgent,
  select,
}) => {
  const getRole = JSON.parse(localStorage.getItem("authRes"));
  const userId = getRole.role === "Admin" ? getRole.role : getRole._id;
  const callBack = () =>
    callAllIssue({
      roleId: userId,
      filterData: IssueDataFiltered,
    });
  useEffect(() => {
    callBack();
  }, [IssueDataFiltered]); //eslint-disable-line
  useEffect(() => {
    if (AllAgent.size === 0) allAgentData();
  }, []); //eslint-disable-line
  return [callBack];
};

const useOnChange = ({
  AllIssueData,
  IssueDataFiltered,
  setReduxData,
  updateAssignee,
  callBack,
}) => {
  const handlePageNation = (value) => {
    setReduxData(
      fromJS({ filterClientIssueData: IssueDataFiltered.set("page", value) })
    );
  };
  const handleChangeAssigneeName = (data) => {
    updateAssignee(data, callBack);
  };
  const pageNo = IssueDataFiltered.get("page", 1);
  const totalPageCount = Math.ceil(AllIssueData.get("Total", 0) / rowsPerPage);

  return [pageNo, totalPageCount, handlePageNation, handleChangeAssigneeName];
};
/*-------------------------------------CustomHooksEnd---------------------------------*/

/*-------------------------------------CompoundStart----------------------------------*/
const IssueData = ({ select, isFromDashboard }) => {
  const [
    callAllIssue,
    SupportClient,
    setReduxData,
    allAgentData,
    updateAssignee,
  ] = useCommonDispatch();
  const [AllIssueData, IssueDataFiltered, AllAgent] = useReduxData();

  const [callBack] = useCallIssueDataApi({
    IssueDataFiltered,
    callAllIssue,
    SupportClient,
    allAgentData,
    AllAgent,
    select,
  });

  const [pageNo, totalPageCount, handlePageNation, handleChangeAssigneeName] =
    useOnChange({
      AllIssueData,
      IssueDataFiltered,
      setReduxData,
      updateAssignee,
      callBack,
    });

  const newAgentData = [...DuplicateAgentData, ...AllAgent.toJS()];

  return (
    <>
      <Box component="main" sx={BoxStyle}>
        {AllIssueData.get("result", List()).size ? (
          <div className="container-fluid" style={componentStyle}>
            <br />
            {select === "CardView" && (
              <>
                <ReportsCardList
                  filteredTableData={AllIssueData}
                  handleChangeAssigneeName={handleChangeAssigneeName}
                  pageNo={pageNo}
                  rowsPerPage={rowsPerPage}
                  newAgentData={newAgentData}
                  callBack={callBack}
                />
                <PaginationControlled
                  currentPage={pageNo}
                  totalPageCount={totalPageCount}
                  float={"none"}
                  switchPagination={handlePageNation}
                />
              </>
            )}
            {/* {select === "TableView" && (
            <>
              <ReportsTable
                filteredTableData={AllIssueData}
                pageNo={pageNo}
                rowsPerPage={rowsPerPage}
                newAgentData={newAgentData}
                handleChangeAssigneeName={handleChangeAssigneeName}
                callBack={callBack}
              />
              <PaginationControlled
                currentPage={pageNo}
                totalPageCount={totalPageCount}
                float={"none"}
                switchPagination={handlePageNation}
              />
            </>
          )} */}
            {select === "TableView" && (
              <TicketsListAgTableView
                tickets={AllIssueData.get("result", List())}
                callBack={callBack}
                allAgents={AllAgent.toJS()}
                handleChangeAssigneeName={handleChangeAssigneeName}
              />
            )}
          </div>
        ) : (
          <p className="h5 fw-bold text-secondary d-flex justify-content-center mt-5">
            No data found...
          </p>
        )}
      </Box>
    </>
  );
};

export default IssueData;
/*-------------------------------------CompoundEnd------------------------------------*/
