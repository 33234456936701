import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Drawer, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function AgTable({
  rows = [],
  columns = [],
  isPagination = true,
  setColumns = () => {},
  agTableWidth = "",
  agContainerStyle = {},
  pageDataCount = 10,
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => ({ ...col, visible: true }))
  );

  columns.push({
    field: "",
    headerComponent: () => (
      <IconButton onClick={() => setDrawerOpen(true)}>
        <ArrowBackIosIcon />
      </IconButton>
    ),
    cellRenderer: () => null,
    width: 50,
    pinned: "right",
  });

  const toggleColumnVisibility = (field) => {
    const updatedColumns = visibleColumns.map((col) =>
      col.field === field ? { ...col, visible: !col.visible } : col
    );
    setVisibleColumns(updatedColumns);
    setColumns(updatedColumns.filter((col) => col.visible));
  };

  return (
    <div className="custom-ag-grid-table" style={agContainerStyle}>
      <div
        className="ag-theme-alpine"
        style={{
          height: "550px",
          ...(agTableWidth && { width: agTableWidth }),
        }}
      >
        <AgGridReact
          rowData={rows}
          columnDefs={columns}
          pagination={isPagination}
          paginationPageSize={pageDataCount}
          rowHeight={50}
        />
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        transitionDuration={700}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            height: "60%",
            position: "fixed",
            top: "30%",
            right: 0,
            transform: "translateY(-50%)",
            borderRadius: "8px",
          },
        }}
        tooltipShowDelay={0}
      >
        <div style={{ padding: "10px" }}>
          <h5>Table Columns</h5>
          {visibleColumns.map((col) => (
            <div>
              <FormControlLabel
                key={col.field}
                control={
                  <Checkbox
                    checked={col.visible}
                    onChange={() => toggleColumnVisibility(col.field)}
                  />
                }
                label={col.field}
              />
            </div>
          ))}
        </div>
      </Drawer>
    </div>
  );
}
