import React, { useState } from "react";
import { MoreVert } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";
import "./AdminTable.css";
import { fromJS } from "immutable";
import { useSelector, useDispatch } from "react-redux";
import {
  selectDepartment,
  selectUsers,
  selectRoles,
} from "../../Redux/TestCase/Selector";
import EditAndDelete from "./Modal/EditAndDelete";
import CreatePopup from "./Modal/createPopup";
import { deleteRole } from "../../Redux/TestCase/Action";
import { deleteCreateDepartment } from "../../Redux/TestCase/Action";
import { postCreateDepartment } from "../../Redux/TestCase/Action";
import { postUserRole } from "../../Redux/TestCase/Action";
import { postAddUserDepartment } from "../../Redux/TestCase/Action";
import Multiselect from "./Modal/Multiselect";

/*---------------------------------------UtilsStart--------------------------------------------*/
const defaultBool = { role: false, department: false };
const initialTableData = {
  openVert: "",
  openIndex: null,
  anchorEl: null,
  selectedRow: null,
  selectedRole: "",
  openAddDialog: defaultBool,
  openDeleteRoleDialog: defaultBool,
  openDialogType: "",
};

/*---------------------------------------UtilsEnd--------------------------------------------*/

/*---------------------------------------CustomHooksStart-------------------------------------*/
const useDepartmentTable = ({ setRole }) => {
  const [tableData, setTableData] = useState(fromJS(initialTableData));
  const dispatch = useDispatch();
  const handleMoreVertClick = (event, row, data, index) => {
    setTableData((pre) =>
      pre
        .set("openVert", data)
        .set("selectedRow", row)
        .set("selectedRole", row)
        .set("anchorEl", event.currentTarget)
        .set("openIndex", index)
    );
  };

  const handleMoreVertClose = () => {
    setTableData((pre) => pre.set("selectedRow", null).set("openVert", ""));
  };

  const handleDeleteRole = (key) => {
    setTableData((pre) =>
      pre.setIn(["openDeleteRoleDialog", key], true).set("openVert", "")
    );
  };

  const handleAddRole = (key) => {
    setTableData((pre) => pre.setIn(["openAddDialog", key], true));
  };

  const handleDeleteDialogClose = () => {
    setTableData((pre) => pre.set("openDeleteRoleDialog", fromJS(defaultBool)));
  };

  const handleDeleteRoleSubmit = (event, role, type) => {
    event.preventDefault();
    setTableData((pre) => pre.set("openDeleteRoleDialog", fromJS(defaultBool)));
    type === "Role"
      ? dispatch(deleteRole(role._id))
      : dispatch(deleteCreateDepartment(role._id));
  };

  const handleAddDialogClose = () => {
    setTableData((pre) =>
      pre.set("openAddDialog", fromJS({ department: false, role: false }))
    );
    // setRole("");
  };

  const handleAddFormSubmit = (event, data) => {
    event.preventDefault();
    tableData.getIn(["openAddDialog", "department"], false) &&
      dispatch(postCreateDepartment({ name: data }));
    tableData.getIn(["openAddDialog", "role"], false) &&
      dispatch(postUserRole({ role: data }));
    setTableData((pre) =>
      pre.set("openAddDialog", fromJS({ department: false, role: false }))
    );
    // setRole("");
  };

  return [
    tableData,
    handleMoreVertClick,
    handleMoreVertClose,
    handleDeleteRole,
    handleAddRole,
    handleDeleteDialogClose,
    handleDeleteRoleSubmit,
    handleAddDialogClose,
    handleAddFormSubmit,
    setTableData,
    dispatch,
  ];
};

/*---------------------------------------CustomHooksEnd-------------------------------------*/

const UserDepartmentTable = ({ role, setRole }) => {
  const [
    tableData,
    handleMoreVertClick,
    handleMoreVertClose,
    handleDeleteRole,
    handleAddRole,
    handleDeleteDialogClose,
    handleDeleteRoleSubmit,
    handleAddDialogClose,
    handleAddFormSubmit,
    setTableData,
    dispatch,
  ] = useDepartmentTable(setRole);

  const allDepartment = useSelector(selectDepartment);
  const users = useSelector(selectUsers);
  const roles = useSelector(selectRoles);

  var userDepartment = {};
  const getMemberOfDepartment = () => {
    var userDepartmentList = allDepartment.toJS().map((department, index) => {
      return {
        [department.departmentName]: users
          .toJS()
          .filter((item) =>
            item?.department?.includes(department.departmentName)
          ),
      };
    });
    for (let i = 0; i < userDepartmentList.length; i++) {
      Object.assign(userDepartment, userDepartmentList[i]);
    }
  };
  getMemberOfDepartment();

  const openDialogTypeClose = () => {
    setTableData((pre) => pre.set("openDialogType", ""));
  };

  const departmentAddMember = (data, department, preMemberId) => {
    const payload = {
      memberId: data.map((item) => item._id),
      preMemberId,
      department,
    };
    dispatch(postAddUserDepartment(payload, () => openDialogTypeClose()));
  };

  return (
    <div className="role-list-container pb-5">
      <h2 className="role-list-title">Department</h2>
      <div className="d-flex align-items-center justify-content-center ">
        <button
          class="icon-btn add-btn ml-5 mb-2"
          onClick={() => handleAddRole("department")}
        >
          <div class="add-icon"></div>
          <div class="btn-txt">Add Department</div>
        </button>
      </div>

      <table className="portal_table">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Department</th>
            <th>dept Members</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allDepartment.toJS().length > 0 &&
            allDepartment.toJS().map((department, index) => (
              <tr key={index} id={index}>
                <td>{index + 1}</td>
                <td>{department.departmentName}</td>
                <td>
                  {users
                    .toJS()
                    .filter((item) =>
                      item?.department?.includes(department.departmentName)
                    )
                    .map((item) => item.username)
                    .join(", ")}
                </td>

                <td key={index}>
                  <MoreVert
                    className="icon"
                    onClick={(event) =>
                      handleMoreVertClick(
                        event,
                        department,
                        "department",
                        index
                      )
                    }
                  />
                  <Menu
                    key={index}
                    id={index}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(
                      tableData.get("openVert", "") === "department" &&
                        tableData.get("openIndex", null) === index
                    )}
                    onClose={handleMoreVertClose}
                    anchorEl={tableData.get("anchorEl", null)}
                  >
                    <MenuItem
                      onClick={() => {
                        setTableData((pre) =>
                          pre
                            .set("openDialogType", "department")
                            .set("openVert", "")
                        );
                      }}
                    >
                      <span className="bold">Add members</span>
                    </MenuItem>

                    <MenuItem
                      key={role.role}
                      onClick={() => handleDeleteRole("department")}
                    >
                      <span className="bold">Delete Department</span>
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {tableData.get("openDialogType", "") === "department" && (
        <Multiselect
          show={true}
          title={"member"}
          handleClose={openDialogTypeClose}
          handleClick={departmentAddMember}
          options={users.toJS().map((item) => {
            return { ...item, label: item.username };
          })}
          department={tableData.getIn(["selectedRole", "departmentName"], "")}
          selected={userDepartment[
            tableData.getIn(["selectedRole", "departmentName"], "")
          ]?.map((item) => {
            return { ...item, label: item.username };
          })}
        />
      )}

      {tableData.getIn(["openDeleteRoleDialog", "department"], false) && (
        <EditAndDelete
          show={tableData.get("openDeleteRoleDialog", "")}
          handleClose={handleDeleteDialogClose}
          handleClick={handleDeleteRoleSubmit}
          title={"Department"}
          displayData={tableData.getIn(["selectedRole", "departmentName"], "")}
          data={tableData.get("selectedRole", "")}
          role={"delete"}
        />
      )}

      {tableData.getIn(["openAddDialog", "department"], false) && (
        <CreatePopup
          show={tableData.getIn(["openAddDialog", "department"], false)}
          handleClose={handleAddDialogClose}
          handleClick={handleAddFormSubmit}
          title={"Department"}
          validate={roles}
        />
      )}
    </div>
  );
};

export default UserDepartmentTable;
