import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getIndividualIssue,
  getSupportClient,
  updateClientStatus,
  getAllAgentData,
  getAllCategoryList,
  setData as toasterSetData,
} from "../../Redux/ClientSupportManagement/Action";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  Tooltip,
} from "@mui/material";

import { TableRow } from "@material-ui/core";
import Loader from "../../LoaderSnackbar/Loader";
import {
  selectCategoryList,
  selectIsLoading,
} from "../../Redux/ClientSupportManagement/Selector";
import "../../index.css";
import {
  selectSupportClientList,
  selectAllAgentData,
  selectMessage,
} from "../../Redux/ClientSupportManagement/Selector";
import { getUserData } from "../../Redux/TestCase/Action";
import { getAssigneeName } from "../ClientSupports/TicketReports/Components/ReportsCardList/ReportsCardList";
import { duplicateNumberForNoAssignee } from "../../Utils/DuplicateNumber";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Map } from "immutable";
import SelectCategory from "./Model/SelectCategory";
import Snackbar from "../../LoaderSnackbar/Snackbar";

const DuplicateAgentData = [
  { username: "No Assignee", userId: duplicateNumberForNoAssignee },
];
const ViewIssue = () => {
  const [issueData, setIssueData] = useState({});
  const [data, setData] = useState(
    Map({
      categoryId: "",
      subCategoryId: "",
      sectionId: "",
      supportId: "",
      newStatus: "",
    })
  );
  const [open, setOpen] = useState(false);
  const loadingStatus = useSelector(selectIsLoading);
  const getAllAgent = useSelector(selectAllAgentData);
  const getAllCategory = useSelector(selectCategoryList);
  const dispatch = useDispatch();
  const location = useLocation();
  const issueId = new URLSearchParams(location.search).get("id");
  const navigate = useNavigate();
  const supportClientListData = useSelector(selectSupportClientList).toJS();
  const messageStatus = useSelector(selectMessage);
  const supportClientList = supportClientListData.find(
    (d) => d._id === issueData?.clientId
  );
  const clientList = getAllAgent
    .map((item) => item.get("username", ""))
    .toJS()
    .join(",");
  const newAgentData = [...DuplicateAgentData, ...getAllAgent.toJS()];
  const handleStatusChange = (event, id) => {
    const updatedData = data
      .set("supportId", id)
      .set("newStatus", event.target.value);

    setData(updatedData);
    setIssueData((prev) => {
      return { ...prev, status: event.target.value };
    });
  };

  const handleDone = () => {
    const payload = {
      supportId: data.get("supportId", "") || issueId,
      currentStatus: data.get("newStatus", ""),
      categoryId: data.get("categoryId", ""),
      ...(data.get("subCategoryId", "") && {
        subCategoryId: data.get("subCategoryId", ""),
      }),
      ...(data.get("sectionId", "") && {
        sectionId: data.get("sectionId", ""),
      }),
    };
    dispatch(updateClientStatus(payload));
    navigate(`/clientSupport`);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleChange = (event, type) => {
    const value = event.target.value;
    let updatedData;
    if (type === "category") {
      updatedData = data
        .set("categoryId", value)
        .remove("subCategoryId")
        .remove("sectionId");
    }
    if (type === "subCategory") {
      updatedData = data.set("subCategoryId", value).remove("sectionId");
    }
    if (type === "section") {
      updatedData = data.set("sectionId", value);
    }

    setData(updatedData);
  };

  useEffect(() => {
    issueId &&
      dispatch(
        getIndividualIssue(issueId, (data) =>
          setIssueData(data.issueIndividualData)
        )
      );
  }, [issueId]); //eslint-disable-line

  useEffect(() => {
    if (supportClientListData.length === 0) dispatch(getSupportClient());
    if (getAllAgent.size === 0) dispatch(getAllAgentData());
    dispatch(getAllCategoryList());
    dispatch(getUserData());
  }, []); //eslint-disable-line

  return (
    <div className="container mt-3 ">
      <Loader isLoading={loadingStatus} />
      <Snackbar
        show={true}
        message={messageStatus}
        reduxSetData={toasterSetData}
      />
      <Card className="w-100 ">
        <div className="d-flex justify-content-around">
          <div>
            <CardHeader title="view info" />
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div className="fs-5">
              Category Selection
              <NewReleasesIcon sx={{ color: "red", height: "15px" }} /> :
            </div>
            <div>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{ height: "28px" }}
              >
                Select Category
              </Button>
            </div>
            {open && (
              <SelectCategory
                open={open}
                setOpen={setOpen}
                handleChange={handleChange}
                category={data}
                getAllCategory={getAllCategory}
                mappedCategoryId={issueData?.categoryId}
              />
            )}
          </div>
        </div>
        <Divider />
        <div className="px-3">
          <Table aria-label="simple table">
            <TableRow>
              <TableCell className="text-uppercase fs_14">Name</TableCell>
              <TableCell>{issueData.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-uppercase fs_14">Email</TableCell>
              <TableCell>{issueData.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-uppercase fs_14 ">
                Client Name
              </TableCell>
              <TableCell>{supportClientList?.clientName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-uppercase fs_14 ">
                <Tooltip title={clientList} placement="right" arrow>
                  Assigned Person
                </Tooltip>
              </TableCell>
              <TableCell>
                {getAssigneeName("userId", issueData.assignee, newAgentData)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="text-uppercase fs_14">priority</TableCell>
              <TableCell>{issueData.priority}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fs-5 text-uppercase fs_14">
                Description
              </TableCell>
              <TableCell>{issueData.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fs-5 text-uppercase fs_14">
                {/* <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Status
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            handleStatusChange("Open", issueId);
                          }}
                        >
                          Open
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            // handleStatusChange("Closed", item.get("_id", ""));
                            handleStatusChange("Closed", issueId);
                          }}
                        >
                          Closed
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                            // handleStatusChange(
                            //   "Inprogress",
                            //   item.get("_id", "")
                            // );
                            handleStatusChange("Inprogress", issueId);
                          }}
                        >
                          Inprogress
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState> */}
                status
              </TableCell>

              <TableCell className="fs-5 text-uppercase fs_14">
                {/* {issueData.status} */}
                <FormControl sx={{ width: "30%" }}>
                  <InputLabel id="demo-simple-select-label">status</InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      issueData.status === undefined ? "" : issueData.status
                    }
                    label="status"
                    onChange={(event) => handleStatusChange(event, issueId)}
                  >
                    <MenuItem value={"Open"}>Open</MenuItem>
                    <MenuItem value={"Close"}>Close</MenuItem>
                    <MenuItem value={"Inprogress"}>Inprogress</MenuItem>
                    <MenuItem value={"Waiting on Customer"}>
                      Waiting on Customer
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fs-5 text-uppercase fs_14">
                Attachment
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="fs-5 text-uppercase fs_14"></TableCell>
              <TableCell
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  width: "90%",
                }}
              >
                {issueData.attachment?.map((image) => {
                  return (
                    <img
                      src={image}
                      alt=""
                      style={{
                        width: "30% ",
                        objectFit: "contain",
                      }}
                      // className="img-fluid img-thumbnail"
                    />
                  );
                })}
              </TableCell>
            </TableRow>
          </Table>
        </div>
        <div className="d-flex justify-content-end mx-5 my-2">
          <button className="btn btn-primary" onClick={handleDone}>
            Done
          </button>
        </div>
      </Card>
    </div>
  );
};

export default ViewIssue;
