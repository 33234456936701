import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import ClientAssigned from "../../Models/ClientAssigned";
import CreateCategory from "../../Models/CreateCategory";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import GridViewIcon from "@mui/icons-material/GridView";

const drawerWidth = 310;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ReportHeader = ({
  select,
  handleSelect,
  handleSubDrawerOpen,
  open,
  setSearchTicket,
}) => {
  const [isPopup, setPopup] = useState(false);
  const [isCategoryPopup, setCategoryPopUp] = useState(false);
  const handleClick = (type) => {
    type === "category" ? setCategoryPopUp(true) : setPopup(true);
  };

  const getSearchTicket = (value) => {
    setSearchTicket(value);
  };

  return (
    <>
      <AppBar
        sx={{
          position: "sticky",
          top: "64px",
          boxShadow: "1",
          zIndex: "1",
          background: "#F5F7F9",
          color: "#3F5566",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-flex align-items-center gap-2">
            <div>
              <Button
                startIcon={<GridViewIcon />}
                sx={{ textTransform: "none" }}
                variant="contained"
                size="small"
                onClick={() => handleSelect("TableView")}
                className={
                  select && select !== "TableView" ? "bg-secondary" : ""
                }
              >
                Table View
              </Button>
            </div>
            <div>
              <Button
                startIcon={<ViewAgendaIcon />}
                sx={{ textTransform: "none" }}
                variant="contained"
                size="small"
                onClick={() => handleSelect("CardView")}
                className={
                  select && select !== "CardView" ? "bg-secondary" : ""
                }
              >
                Card View
              </Button>
            </div>
          </div>
          {select === "CardView" && (
            <div className="search-tickets">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder="Search Tickets"
                className="w-20 mt-2"
                size="small"
                onChange={(e) => getSearchTicket(e.target.value)}
              />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center  gap-4">
            <Button
              startIcon={<AddIcon />}
              className="client_create_btn"
              onClick={() => handleClick("category")}
            >
              Create Category
            </Button>
            <Button
              startIcon={<AddIcon />}
              className="client_create_btn"
              onClick={() => handleClick()}
            >
              Create Client
            </Button>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleSubDrawerOpen}
            >
              {open ? (
                <ClearIcon sx={{ color: "#3F5566" }} />
              ) : (
                <MenuIcon sx={{ color: "#3F5566" }} />
              )}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {isPopup && <ClientAssigned isPopup={isPopup} setPopup={setPopup} />}
      {isCategoryPopup && (
        <CreateCategory isPopup={isCategoryPopup} setPopup={setCategoryPopUp} />
      )}
    </>
  );
};

export default ReportHeader;
